<script setup>
import router from '@/router/index'
import { computed, ref, onBeforeMount } from 'vue'
import { maska as vMaska } from 'maska'
import axios from 'axios'
import { storeToRefs } from 'pinia'
import {
  internalZipLookup,
  ipqsCheckIfPhoneIsValid,
  ipqsIsEmailInvalid,
  extractUrlParams,
  EMAIL_REGEX,
  setEverflow,
  getJornayaLeadIdToken,
  getTrustedForm,
  getIpAddress,
  getChannel,
  isNameGibberish,
} from '@/js/utils'
import { blacklist } from '@/js/badwordslist'

import TCPA from '@/components/shared/TCPA'
import TwilioModal from '@/components/shared/TwilioModal'

const props = defineProps({
  sms_verify: {
    required: false,
    default: false,
    type: Boolean
  }
})

//store modules
import { useTwilioStore } from '@/store/twilio'

const twilioStore = useTwilioStore()
const { toggle_modal, approved } = storeToRefs(twilioStore)

const PATTERN_BLACK_LIST = new RegExp(`(^|\\W)(${blacklist.join('|')})($|\\W)`, 'gmi')

const offer_id = 110
const trustedForm = ref({
  id: '',
  url: ''
})
const ipAddress = ref('')
const jornaya_id = ref('')
const everflow_id = ref('')

const firstName = ref({
  val: '',
  error: false
})

const lastName = ref({
  val: '',
  error: false
})

const zip = ref({
  val: '',
  error: false,
})

const zipData = ref({
  county: '',
  city: '',
  stateCode: ''
})

const phone = ref({
  val: '',
  error: false
})

const email = ref({
  val: '',
  error: false
})

const type_of_damage_to_property = ref('')
let type_of_damage_to_property_error = ref(false)
const insurance_for_damaged_property = ref('')
let insurance_for_damaged_property_error = ref(false)
const owns_home = ref('')
let owns_home_error = ref(false)

const validEmail = computed(() => EMAIL_REGEX.test(String(email.value.val).toLowerCase()))
const formHasError = computed(() =>
  firstName.value.error ||
  lastName.value.error ||
  zip.value.error ||
  phone.value.error ||
  email.value.error ||
  type_of_damage_to_property_error.value ||
  insurance_for_damaged_property_error.value ||
  owns_home_error.value
)
const loading = ref(false)

const packageLeads = () => {
  const params = extractUrlParams()
  let userAgent = 'userAgent' in navigator ? navigator.userAgent : ''

  if (params['_ef_transaction_id']) {
    delete params['_ef_transaction_id']
  }

  let nameGibberish = isNameGibberish(firstName.value.val) ? 'yes' : 'no'
  if (nameGibberish === 'no') {
    nameGibberish = isNameGibberish(lastName.value.val) ? 'yes' : 'no'
  }

  let nameBadWord = PATTERN_BLACK_LIST.test(firstName.value.val) ? 'yes' : 'no'
  if (nameBadWord === 'no') {
    nameBadWord = PATTERN_BLACK_LIST.test(lastName.value.val) ? 'yes' : 'no'
  }

  return {
    lp_campaign_id: params['lp_campaign_id'] || 6949,
    lp_supplier_id: params['lp_supplier_id'] || 14132,
    lp_key: params['lp_key'] || '3o6am62hdvy0',
    lp_action: params['lp_action'] || '',
    ...params,
    first_name: firstName.value.val,
    last_name: lastName.value.val,
    name_gibberish: nameGibberish,
    name_bad_word: nameBadWord,
    email: email.value.val,
    phone: phone.value.val.replace(/[()\-\s]+/g, ""),
    zip_code: zip.value.val,
    city: zipData.value.city,
    state: zipData.value.stateCode,
    county: zipData.value.county,
    sms_verified: props.sms_verify ? 'yes' : 'no',
    owns_home: owns_home.value,
    type_of_damage_to_property: type_of_damage_to_property.value,
    has_insurance_for_damaged_property: insurance_for_damaged_property.value,
    user_agent: userAgent,
    trusted_form_cert_id: trustedForm.value.id,
    trusted_form_cert_url: trustedForm.value.url,
    ip_address: ipAddress.value,
    channel: getChannel(),
    jornayaId: jornaya_id.value,
    ef_transaction_id: everflow_id.value,
    referrer: 'hurricane-ian-settlement',
    oid: offer_id,
    affid: params.affid || 1
  }
}

const setZipData = ({county, city, stateCode}) => {
  zipData.value.county = county
  zipData.value.city = city
  zipData.value.stateCode = stateCode
}

const validateNames = () => {
  firstName.value.error = firstName.value.val.length < 3
  lastName.value.error = lastName.value.val.length < 3
}

const validateZip = async () => {
  zip.value.error = zip.value.val.length < 5
  if (zip.value.error) return

  const zipData = await internalZipLookup(zip.value.val)
  zip.value.error = zipData.zipError
  if (zip.value.error) return
  setZipData(zipData)
}

const validatePhone = async () => {
  phone.value.error = phone.value.val.length < 14
  if (phone.value.error) return
  ipqsCheckIfPhoneIsValid(phone.value.val)
    .then(async ({ fullValid }) => {
      phone.value.error = !fullValid

      if (formHasError.value || approved.value) return

      if (props.sms_verify) {
        twilioStore.toggleModal()
        await twilioStore.postPhone(phone.value.val)
      }
    })
}

const validateEmail = async () => {
  email.value.error = !validEmail.value
  if (email.value.error) return
  email.value.error = await ipqsIsEmailInvalid(email.value.val)
}

const validateRadioInputs = () => {
  type_of_damage_to_property_error.value = !type_of_damage_to_property.value
  insurance_for_damaged_property_error.value = !insurance_for_damaged_property.value
  owns_home_error.value = !owns_home.value
}

const validate = async () => {
  loading.value = true

  validateNames()
  validateRadioInputs()

  const promises = [
    await validateZip(),
    await validatePhone(),
    await validateEmail()
  ]
  await Promise.allSettled(promises)

  if (formHasError.value || (props.sms_verify && !approved.value)) {
    loading.value = false
    return
  }

  postData()
}

const postData = () => {
  /*
  * if the emit from twilio sms verify component is triggered, we want to make sure all data is valid before submitting
  * if the user submits valid data in one go, and has to verify their phone, once they verify they can submit without
  * having the need to press the actual submit button again
  **/

  if (formHasError.value) {
    loading.value = false
    return
  }

  loading.value = true

  const data = packageLeads()
  axios
    .post('https://control.org31415.dev/api/leads/ingest', data)
    .then(resp => {
      loading.value = false
      router.push(
        {
          path: '/thank-you',
          query: {
            ef_transaction_id: data.ef_transaction_id,
            affid: data.affid,
            sub_id: data.sub_id
          }
        }
      )
    })
    .catch(e => {
      throw Error(`Error posting - Hurricane Ian SPA Form => ${e}`)
    })
}

const phoneHandler = () => {
  phone.value.error = false
  twilioStore.$reset()
}

onBeforeMount(() => {
  window.loadJornayaLeadId('e774bd91-30ba-8c48-402a-502fde7ecc88')

  setEverflow(offer_id).then(({ef_transaction_id}) => {
    everflow_id.value = ef_transaction_id
  })

  getJornayaLeadIdToken().then(jornayaId => {
    jornaya_id.value = jornayaId
  })

  getTrustedForm().then(({ trustedFormId, trustedFormUrl }) => {
    trustedForm.value.id = trustedFormId
    trustedForm.value.url = trustedFormUrl
  })

  getIpAddress().then((ip) => {
    ipAddress.value = ip
  })
})
</script>

<template>
  <div class="storm-damage-container">
    <TwilioModal
      v-show="toggle_modal"
      @checkSubmit="postData"
    />
    <div
      v-if="loading"
      class="disabled-overlay"
    >
      <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="storm-damage-wrapper">
      <div class="text-container">
        <h2>
          We fight for those affected by Hurricanes & Storm Damage.
        </h2>
        <p>
          Sign up for a <strong>Confidential</strong> Case Review to find out if you’re eligible for <strong>financial compensation</strong>.
        </p>
      </div>

      <div class="form-container">
        <div class="row">
          <div
            class="input-group"
            :class="{'error': firstName.error}"
          >
            <label for="first_name">
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              placeholder="John"
              v-model="firstName.val"
              @input="firstName.error = false"
            >

            <div class="error-container">
              <span v-if="firstName.error">Please provide a first name</span>
            </div>
          </div>

          <div
            class="input-group"
            :class="{'error': lastName.error}"
          >
            <label for="last_name">
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              placeholder="Doe"
              v-model="lastName.val"
              @input="lastName.error = false"
            >
            <div class="error-container">
              <span v-if="lastName.error">Please provide a last name</span>
            </div>
          </div>
        </div>

        <div class="row input-group-row">
          <div
            class="input-group"
            :class="{'error': zip.error}"
          >
            <label for="zip">Zip</label>
            <input
              type="text"
              id="zip"
              placeholder="90210"
              v-maska="'#####'"
              v-model="zip.val"
              @input="zip.error = false"
            >
            <div class="error-container">
              <span v-if="zip.error">Please provide a valid zip code</span>
            </div>
          </div>

          <div
            class="input-group"
            :class="{'error': phone.error}"
          >
            <label for="phone">
              Phone
            </label>
            <input
              type="text"
              id="phone"
              placeholder="(888) 412-1858"
              v-maska="'(###) ###-####'"
              v-model="phone.val"
              @input="phoneHandler"
            >
            <div class="error-container">
              <span v-if="phone.error">Please provide a valid phone number</span>
            </div>
          </div>
        </div>

        <div class="row input-group-row last-input-group-row">
          <div
            class="input-group"
            :class="{'error': email.error}"
          >
            <label for="email">
              Email
            </label>
            <input
              type="text"
              id="email"
              placeholder="john.doe@gmail.com"
              v-model="email.val"
              @input="email.error = false"
            >
            <div class="error-container">
              <span v-if="email.error">Please provide a valid email</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="inner-col">
            <h3 class="radio-question-text">Do you own your home?</h3>
            <div class="radio-input-groups">
              <div class="radio-input-group">
                <div class="radio-wrapper">
                  <input
                    type="radio"
                    id="owns_home_yes"
                    name="radio-grp-1"
                    value="yes"
                    class="radio-answer"
                    @change="owns_home_error = false"
                    v-model="owns_home"
                  >
                  <label
                    for="owns_home_yes"
                    :class="{'radio-error' : owns_home_error === true}"
                  >
                    Yes
                  </label>
                </div>
              </div>

              <div class="radio-input-group">
                <div class="radio-wrapper">
                  <input
                    type="radio"
                    id="owns_home_no"
                    name="radio-grp-1"
                    value="no"
                    class="radio-answer"
                    @change="owns_home_error = false"
                    v-model="owns_home"
                  >
                  <label
                    for="owns_home_no"
                    :class="{'radio-error' : owns_home_error === true}"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="inner-col">
            <h3 class="radio-question-text">What type of damage occurred to the property?</h3>
            <div class="radio-input-groups">
              <div class="radio-input-group">
                <div class="radio-wrapper">
                  <input
                    type="radio"
                    id="hurricane_damage"
                    class="radio-answer"
                    name="radio-grp-2"
                    value="hurricane_damage"
                    @change="type_of_damage_to_property_error = false"
                    v-model="type_of_damage_to_property"
                  >
                  <label
                    for="hurricane_damage"
                    :class="{'radio-error' : type_of_damage_to_property_error === true}"
                  >
                    Hurricane Damage
                  </label>
                </div>
              </div>

              <div class="radio-input-group">
                <div class="radio-wrapper">
                  <input
                    type="radio"
                    id="flood_damage"
                    class="radio-answer"
                    name="radio-grp-2"
                    value="flood_damage"
                    @change="type_of_damage_to_property_error = false"
                    v-model="type_of_damage_to_property"
                  >
                  <label
                    for="flood_damage"
                    :class="{'radio-error' : type_of_damage_to_property_error === true}"
                  >
                    Flood Damage
                  </label>
                </div>
              </div>

              <div class="radio-input-group">
                <div class="radio-wrapper">
                  <input
                    type="radio"
                    id="hail_damage"
                    class="radio-answer"
                    name="radio-grp-2"
                    value="hail_damage"
                    @change="type_of_damage_to_property_error = false"
                    v-model="type_of_damage_to_property"
                  >
                  <label
                    for="hail_damage"
                    :class="{'radio-error' : type_of_damage_to_property_error === true}"
                  >
                    Hail Damage
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="inner-col">
            <h3 class="radio-question-text">Do you have insurance for the damaged property?</h3>
            <div class="radio-input-groups">
              <div class="radio-input-group">
                <div class="radio-wrapper">
                  <input
                    type="radio"
                    id="insurance_for_damage_property_yes"
                    class="radio-answer"
                    name="radio-grp-3"
                    value="yes"
                    @change="insurance_for_damaged_property_error = false"
                    v-model="insurance_for_damaged_property"
                  >
                  <label
                    for="insurance_for_damage_property_yes"
                    :class="{'radio-error' : insurance_for_damaged_property_error === true}"
                  >
                    Yes
                  </label>
                </div>
              </div>

              <div class="radio-input-group">
                <div class="radio-wrapper">
                  <input
                    type="radio"
                    id="insurance_for_damage_property_no"
                    class="radio-answer"
                    name="radio-grp-3"
                    value="no"
                    @change="insurance_for_damaged_property_error = false"
                    v-model="insurance_for_damaged_property"
                  >
                  <label
                    for="insurance_for_damage_property_no"
                    :class="{'radio-error' : insurance_for_damaged_property_error === true}"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <button
            class="submit-button"
            :disabled="loading"
            @click="validate"
          >
            Submit
          </button>
        </div>

        <div class="row">
          <TCPA/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.storm-damage-container
  height: 100%
  width: 100%
  background-color: #fff
  border-radius: 5px
  padding: 20px
  -webkit-box-shadow: 0px 4px 8px rgba(0,0,0,0.3)
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3)
  .storm-damage-wrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    gap: 20px
    .text-container
      display: flex
      justify-content: center
      align-items: flex-start
      flex-direction: column
      gap: 10px
      text-align: left
      h2
        color: #162d59
        font-weight: 500
        font-size: 28px
        line-height: 38px
      p
        font-size: 18px
        line-height: 24px
        color: #162d59

    .form-container
      width: 100%
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      gap: 25px
      .row
        display: flex
        justify-content: flex-start
        align-items: center
        width: 100%
        gap: 10px
        .inner-col
          display: flex
          flex-direction: column
          justify-content: center
          align-items: flex-start
          width: 100%
          gap: 15px
      .row.input-group-row
        margin-top: 25px
      .row.input-group-row.last-input-group-row
        margin-bottom: 20px
      .input-group
        padding: 10px
        border: 1px solid #0d2e5c
        width: 100%
        height: 40px
        border-radius: 5px
        input
          width: 100%
          height: 25px
          position: relative
          top: -18px
          left: 0
          border: none
          font-size: 16px
          background-color: #fff
          &:focus
            outline: none
          &::placeholder
            padding-left: 2px
        label
          position: relative
          top: -20px
          left: 0px
          padding: 5px
          font-weight: 500
          background-color: #fff
          color: #0d2e5c
          text-align: unset
        .error-container
          height: 15px
          position: relative
          margin-top: -10px
          margin-bottom: 0px
          letter-spacing: 0
          span
            left: 5px
            color: red
            font-family: Verdana, sans-serif
            font-size: 13px
            font-weight: 300
        &.error
          border: 1px solid red
      .radio-input-groups
        display: flex
        justify-content: flex-start
        align-items: center
        gap: 10px
        width: 100%
        .radio-input-group
          display: flex
          justify-content: center
          align-items: center
          gap: 10px
          .radio-answer, label
            display: inline-block
            vertical-align: middle
            margin: 5px
            cursor: pointer
          .radio-answer + label:before
            content: ''
            background: #fff
            border: 2px solid #063665
            display: inline-block
            vertical-align: middle
            width: 20px
            height: 20px
            padding: 2px
            margin-right: 10px
            text-align: center
            border-radius: 50%
          .radio-answer:checked + label:before
            background-color: #053564
            box-shadow: inset 0px 0px 0px 4px #fff
          .radio-answer + label.radio-error:before
            border: 1px solid red
          label
            font-size: 16px
            position: relative
          .radio-wrapper
            .radio-answer
              opacity: 0
              position: absolute
  .submit-button
    width: 100%
    height: 45px
    background-color: #002f5f
    border: none
    color: #fff
    border-radius: 5px
    font-size: 18px
    &:hover
      background-color: rgb(14, 46, 106)
      cursor: pointer
h2
  font-weight: 500

@media screen and (max-width: 1150px)
  .storm-damage-container .storm-damage-wrapper .form-container
    .radio-input-groups
      display: grid
      grid-template-columns: repeat(2, 1fr)
      width: unset
      .radio-input-group
        justify-content: flex-start

@media screen and (max-width: 1100px)
  .storm-damage-container .storm-damage-wrapper .form-container
    gap: 15px
    .row
      flex-direction: column
      justify-content: center
      gap: 50px

@media screen and (max-width: 910px)
  .storm-damage-container .storm-damage-wrapper .form-container .radio-input-groups
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start

@media (max-width: 600px)
  .storm-damage-container .storm-damage-wrapper .form-container .radio-input-groups
    display: block
    .radio-input-group
      justify-content: left
</style>