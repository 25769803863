import { defineStore } from 'pinia'
import axios from 'axios'

export const useTwilioStore = defineStore('twilio', {
  state: () => ({
    phone: '',
    status: false,
    code: '',
    approved: false,
    loading: false,
    raw_phone: '',
    toggle_modal: false,
    resend_code_cool_down: false,
    phone_error: false
  }),
  actions: {
    setStatus(status) {
      this.status = status
    },
    setPhone(phone) {
      this.phone = phone
    },
    setRawPhone(raw_phone) {
      this.raw_phone = raw_phone
    },
    setCode(code) {
      this.code = code
    },
    toggleModal() {
      this.toggle_modal = !this.toggle_modal
    },
    setResendCooldown() {
      this.resend_code_cool_down = true

      setTimeout(() => {
        this.resend_code_cool_down = false
      }, 30000)
    },
    setLoading(loading) {
      this.loading = loading
    },
    setApproved(approved) {
      this.approved = approved
    },
    async postPhone(phone) {
      this.setRawPhone(phone)

      const formattedPhone = `+1${phone}`.replace(/[()\-\s]+/g, "")
      this.setPhone(formattedPhone)

      if (this.resend_code_cool_down || this.status) return

      const { sent } = await this.twilioPhonePost({ phone: formattedPhone })

      this.setStatus(sent)
    },
    async postCode() {
      this.setLoading(true)
      const approved = await this.twilioCodePost({
        phone: this.phone,
        sentStatus: this.status,
        code: this.code
      })

      if (approved) {
        this.phone_error = false
        this.toggleModal()
      }

      this.setApproved(approved)
      this.setLoading(false)
    },
    async resendCode() {
      if (this.resend_code_cool_down) return

      this.setStatus(false)
      this.postPhone(this.raw_phone).then()
      this.setResendCooldown()
    },
    async twilioPhonePost(phone) {
      const url = 'https://proxy.leadprosper.tech/two-step-verification/phone'
      let response = {}

      try {
        const respFromReq = await axios.post(url, phone)
        response = respFromReq.data

      } catch (err) {
        console.log(err)
        return { sent: false }
      }

      return response
    },
    async twilioCodePost(code) {
      const url = 'https://proxy.leadprosper.tech/two-step-verification/code'
      let response = false

      try {
        const respFromReq = await axios.post(url, code)
        response = respFromReq.data.status === 'approved'
      } catch (err) {
        console.log(err)
      }

      return response
    }
  }
})