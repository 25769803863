<script setup>
import { onBeforeMount } from 'vue'

import Article from '@/components/hurricaneian_components/Article'
import Form from '@/components/hurricaneian_components/Form'

//TODO: Need OID and Jornaya Lead ID

onBeforeMount(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})
</script>

<template>
  <div class="container">
    <Article/>
    <div class="col">
      <Form/>
    </div>
    <Article :config="'mobile'"/>
  </div>
</template>

<style scoped lang="sass">
.container
  display: flex
  justify-content: center
  align-items: flex-start
  gap: 20px
  .col
    width: 100%
    max-width: 600px
    height: 100%

@media screen and (max-width: 765px)
  .container
    flex-direction: column
    align-items: center
</style>