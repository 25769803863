<script setup>
import { reactive, watch } from 'vue'
import { storeToRefs } from 'pinia'

//store module imports
import { useTwilioStore } from '@/store/twilio'
const twilioStore = useTwilioStore()
const { resend_code_cool_down, approved, loading } = storeToRefs(twilioStore)

const state = reactive({
  verificationCode: '',
  showErrorMsg: false,
})
import { maska as vMaska } from 'maska'

const emit = defineEmits(['checkSubmit'])

watch(() => [state.verificationCode], () => {
  if (state.verificationCode.length > 0) state.showErrorMsg = false
})
const closeModal = () => {
  document.body.classList.remove('modal-open')
  twilioStore.toggleModal()
}
const resendCode = () => {
  twilioStore.resendCode()
}
const verify = async () => {
  if (state.verificationCode.length < 6) {
    state.showErrorMsg = true
    return
  }

  twilioStore.setCode(state.verificationCode)
  await twilioStore.postCode()
  if (approved.value) {
    emit('checkSubmit')
    return
  }
  state.showErrorMsg = true
}
</script>

<template>
  <div class="twilio-modal">
    <div class="twilio-container">
      <img
        src="../../assets/svg/close.svg"
        class="close-modal"
        alt=""
        @click="closeModal"
      >
      <div class="twilio-wrapper">
        <h2 class="twilio-verify-header">
          <img class="sms-svg" src="../../assets/svg/sms.svg" alt="sms-icon">
          Verification code
        </h2>
        <p class="twilio-verify-subheader">
          Enter your verification code that we sent to
        </p>
        <input
          v-model="state.verificationCode"
          :class="{'border-error': state.showErrorMsg}"
          type="text"
          id="twilio-verify"
          class="form-field twilio-input"
          v-maska="'######'"
          placeholder="000000"
        />
        <div
          class="error-msg twilio-failed-verification"
        >
          <span v-if="state.showErrorMsg">
            Verification failed, please enter the code you've received
          </span>
        </div>
        <div class="resend-verification">
          <span
            @click="resendCode"
            :class="{'disabled-resend-twilio' : resend_code_cool_down}"
          >
            {{ resend_code_cool_down ? 'New code has been sent!' : `Didn't get the code? Send a new one` }}
          </span>
        </div>

        <div class="button-block">
          <button
            @click="verify"
            :class="{ 'btn-disabled': loading }"
            :disabled="loading"
            class="question-button small-question-button"
          >
            {{ loading ? 'Verifying...' : 'Submit' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.twilio-modal {
  background-color: rgba(0,0,0,.4);
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  font-family: "Poppins", sans-serif;
  .twilio-container {
    align-items: center;
    background-color: #fefefe;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin: auto;
    .close-modal {
      margin-left: auto;
      height: 25px;
      width: 25px;
      &:hover {
        cursor: pointer;
      }
    }
    .twilio-wrapper {
      width: 100%;
      .twilio-input {
        text-align: left;
        font-size: 20px;
        max-width: 400px;
        width: 100%;
        min-height: 50px;
        background-color: #edf2f7;
        border: 1px solid #d2d6dc;
        padding: 10px;
        border-radius: 5px;
        &.border-error {
          border: 2px solid red;
        }
      }
      .twilio-failed-verification {
        color: #ff0014;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
        min-height: 21px;
        padding-top: 2px;
      }
      .resend-verification {
        color: #147bc9;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        &:hover {
          color: #153c86;
        }
        .disabled-resend-twilio {
          color: #0f5e9a;
          cursor: not-allowed;
        }
      }
      .button-block {
        text-align: center;
        .question-button {
          margin: 5px;
          background-color: #f2671f;
          cursor: pointer;
          padding: 16px;
          font-size: 20px;
          letter-spacing: 2px;
          color: #fff;
          border-radius: 0.25rem;
          border: none;
          &:hover {
            background-color: #ea5d08;
            box-shadow: 0 1px 7px -3px #000000;
          }
          &.btn-disabled {
            opacity: .7;
            cursor: not-allowed;
          }
          &.btn-disabled:hover {
            transform: translate(0, 0);
            box-shadow: none;
          }
        }
      }
      .twilio-verify-header {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        margin-bottom: 12px;
        margin-top: 5px;
        color: #162D59;
        font-weight: 500;
        font-size: 32px;
        .sms-svg {
          height: 35px;
          width: 35px;
          margin-right: 8px;
        }
      }
      .twilio-verify-subheader {
        font-weight: 400;
        font-size: 17px;
        line-height: 26px;
        margin-bottom: 12px;
        text-align: left;
        .twilio-phone {
          display: inline-block;
          font-weight: 600;
          letter-spacing: 1px;
        }
      }
    }
  }
}
</style>