<template>
  <div class="container banner">
    <div class="wrapper">
      <h2>
        IF YOU WERE AFFECTED BY HURRICANE IAN TIME IS LIMITED TO FILE A CLAIM - ACT NOW
      </h2>
    </div>
  </div>
</template>

<style scoped lang="sass">
.banner
  max-width: none
  margin-top: 75px
  background-color: #dd1515
  color: #fff
  padding: 10px
  .wrapper
    max-width: 768px
    width: 100%
    text-align: center
    margin: 0 auto
    h2
      font-weight: bold
</style>