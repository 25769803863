<script setup>
import Header from '@/components/shared/Header'
import Banner from '@/components/hurricaneian_components/Banner'
import Root from '@/components/hurricaneian_components/Root'
import Footer from '@/components/shared/Footer'

const urlParams = new URLSearchParams(location.search)
if (urlParams.get('affid') === '224') {
  window.loadTikTokPixelScript('CDC51RRC77U1JEFR54LG')
  ttq.track('ViewContent')
}
</script>

<template>
  <Header/>
  <Banner/>
  <main>
    <Root/>
  </main>
  <Footer/>
</template>
